import React from 'react'
import { Button, ButtonText, Card, CardContainer, Container, Emoji, Heading, Mid, Title } from '../styles'

const Landing = () => {
    return (
        <Container>
            <Heading>

           <Title>NBA DECK</Title> 
           <Emoji>🏀  </Emoji>
            </Heading>

            <Mid>

            <CardContainer>
                <Card/>
                <Card/>
            </CardContainer>
            <Button><ButtonText>Ball in →</ButtonText></Button>
            </Mid>
        </Container>
    )
}

export default Landing
