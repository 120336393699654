import { ThemeStore } from './context';
import Theme from './styles/Theme';
import './App.css';
import Landing from './components/Landing';

function App() {
  return (
    <ThemeStore>
      <Theme>
        <Landing/>
        </Theme>
        </ThemeStore>
  );
}

export default App;
